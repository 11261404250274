import React, {Component} from 'react';
import {Link} from "react-scroll";

import "../stylesheets/Header.scss";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: [{
                title: "Home",
                href: "home"
            }, {
                title: "About",
                href: "about"
            }, {
                title: "Services",
                href: "services"
            }, {
                title: "Biokinetics",
                href: "bio"
            }, {
                title: "Contact",
                href: "contact"
            }]
        };
    }

    render() {
        return (
            <header className="header d-none d-lg-block">
                <ul className="nav justify-content-center">
                    {
                        this.state.navigation.map((item, index) => {
                            return (
                                <li className="nav-item text-uppercase" key={index}>
                                    <Link
                                        activeClass="active"
                                        to={item.href}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={1000}
                                    >
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })
                    }
                </ul>
            </header>
        );
    }

}

export default Header;
