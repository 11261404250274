import React, {Component} from 'react';

import "../../stylesheets/OwnerDetail.scss";

import AltHeader from "../AltHeader";
import Copyright from "../Copyright";
import Footer from "../Footer";

class OwnerDetail extends Component {

    state = {
        owners: [{
            id: 1,
            img: "/images/owners/chineldi.jpg",
            name: "Chineldi Pienaar",
            blurb: "Chineldi has a passion for people and loves her practice. She has a special interest in orthopaedic " +
                "conditions, as well as performance enhancement and injury prevention in young sportsmen and women.",
            experience: [{
                year: "2002:",
                detail: "B.Com Sport Management degree at RAU"
            }, {
                year: "2003:",
                detail: "B.Com Biokinetics degree at RAU"
            }, {
                year: "2005:",
                detail: "Started Chineldi Pienaar Biokineticist"
            }, {
                year: "2005 - 2011:",
                detail: "Assessing and consulting to the Golden Lions Rugby Union U/19, U/21, Vodacom Cup, Currie Cup and Super 15 teams"
            }, {
                year: "2009 - 2011:",
                detail: "Orlando Pirates senior soccer team rehabilitation"
            }, {
                detail: "Assessments and training programs for Athletics SA"
            }, {
                detail: "Assessment and rehabilitation for Helpmekaar Kollege, Paarl Gimnasium, Paarl Boys High, St Stithians College, Hoërskool Waterkloof  and Hoërskool Linden"
            }, {
                year: "2017 - Current:",
                detail: "Vice chair for the Gauteng Biokinetics Association"
            }, {
                year: "2021:",
                detail: "Biokineticist at Pienaar & Willis Biokineticist & Chineldi Pienaar Biokineticist"
            }],
            className: "chineldi"
        }, {
            id: 2,
            img: "/images/owners/leandri.jpg",
            name: "Leandri Willis",
            blurb: "Leandri is passionate about sport, she has a special interest in athletics and loves working with " +
                "different types of sport related injuries.",
            details: "Leandri participated in different types of sports including Trampoline, Gymnastics, Netball and Hockey. " +
                "She competed at a high level in Long Jump and enjoys staying active. Her hobbies include hiking, running, " +
                "working with athletes and being outdoors.",
            experience: [{
                year: "2015:",
                detail: "Degree in Human Movement Science at the University of Pretoria"
            }, {
                year: "2016:",
                detail: "Honours in Sport and Recreation Management at the University of Pretoria"
            }, {
                year: "2017:",
                detail: "Bachelors of Health Sciences with Honours in Biokinetics at the University of Witwatersrand"
            }, {
                year: "2018:",
                detail: "Internship at Pienaar & Bresler Biokineticists"
            }, {
                year: "2019:",
                detail: "Biokineticist at Pienaar & Bresler Biokineticists"
            }, {
                year: "2020:",
                detail: "Made partner at Pienaar & Willis Biokineticists"
            }, {
                year: "2021:",
                detail: "Biokineticist at Pienaar & Willis Biokineticist"
            }],
            className: "leandri"
        }, {
            id: 3,
            img: "/images/owners/leane.jpg",
            name: "Leané Du Plessis",
            blurb: "Leané lives out her love for the human body through Biokinetics, where she has a big interest in " +
                "Orthopaedic conditions as well as Neurological conditions.",
            details: "Leané has a passion for Dancing, adrenaline driven outdoor activities. " +
                "Leané also loves taking part in team sports like Crossfit and spending time with her horses.",
            experience: [{
                year: "2016:",
                detail: "Bsc Degree in Human Movement Science and Physiology (NWU: Pukke)"
            }, {
                year: "2017:",
                detail: "BSc Hons. Degree in Biokinetics (NWU: Pukke)"
            }, {
                year: "2018:",
                detail: "Finished Internship at Pienaar & Bresler Biokineticists"
            }, {
                year: "2019:",
                detail: "Jnr. Biokineticist at Pienaar & Bresler Biokineticists"
            }, {
                year: "2020:",
                detail: "Biokineticist at Pienaar & Willis Biokineticists"
            }, {
                year: "2021:",
                detail: "Biokineticist at Pienaar & Willis Biokineticists"
            }],
            className: "leane"
        }, {
            id: 4,
            img: "/images/owners/miandi.jpg",
            name: "Miandi Meyer",
            blurb: "Miandi has always been fascinated by health and the human body. She is passionate about people and " +
                "being a Biokineticist enables her to make a positive contribution to people’s lives by improving " +
                "their wellbeing.",
            details: "Her interests lies in die fields of Orthopedic & Neurological conditions. She enjoys activities " +
                "such as running and dancing. She ran competitively at school, both sprints " +
                "& long distance and now runs for leisure. Miandi completed her Ballet, Royal Academy of Dance (RAD), " +
                "and teacher’s qualification with distinction.",
            experience: [{
                year: "2016:",
                detail: "She completed her BA HMS at the University of Pretoria"
            }, {
                year: "2018:",
                detail: "BHSc. (Hons) at Wits University"
            }, {
                year: "2019:",
                detail: "She is currently doing her Internship at Pienaar & Bresler Biokineticists"
            }, {
                year: "2020:",
                detail: "Biokineticist at Pienaar & Willis Biokineticists"
            }, {
                year: "2021:",
                detail: "Biokineticist at Chineldi Pienaar Biokineticist"
            }],
            className: "miandi"
        }, {
            id: 5,
            img: "/images/owners/kalinka.jpg",
            name: "Kalinka Terre Blanché",
            blurb: "Kalinka just finished her Honors Degree in Biokinetics at the University of the North West during which " +
                "she found a special interest in specifically rehabilitation of sport injuries, children and posture related injuries. " +
                "Kalinka enjoys Crossfit, running and being outdoors. She firmly believes in the saying practice what you " +
                "preach and that with hard work there is little one cannot achieve.",
            experience: [{
                year: "2018:",
                detail: "BSc Human Movement sciences"
            }, {
                year: "2019:",
                detail: "BHons Biokinetics"
            }, {
                year: "2019:",
                detail: "Sports Massage Course"
            }, {
                year: "2020:",
                detail: "She is currently doing her Biokinetics Internship at Pienaar & Willis Biokineticists"
            }, {
                year: "2021:",
                detail: "Biokineticist at Pienaar & Willis Biokineticists & Chineldi Pienaar Biokineticist"
            }],
            className: "kalinka"
        }, {
            id: 6,
            img: "/images/owners/kathryn.jpg",
            name: "Kathryn Brandt",
            blurb: "Kathryn recently graduated with BHSC Honours in Biokinetics at Wits University where she found interest " +
                "in chronic disease management, children and sports related injury. Kathryn enjoys Pilates and various " +
                "forms of cardio such as swimming and running. Kathryn believes strongly in helping others to reach " +
                "their goals and improving their abilities.",
            experience: [{
                year: "2018:",
                detail: "She qualified in BSS in Human Kinetics, Ergonomics and Psychology at Rhodes University"
            }, {
                year: "2019:",
                detail: "BHSC honours in Biokinetics at Wits University"
            }, {
                year: "2020:",
                detail: "She is currently doing her Biokinetics Internship at Pienaar & Willis Biokineticists"
            }, {
                year: "2021:",
                detail: "Biokineticist at Chineldi Pienaar Biokineticist"
            }],
            className: "kathryn"
        }],
        selected: {}
    };

    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({
            selected: this.state.owners.find(owner => owner.id === Number(params.id))
        });
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main>
                <AltHeader/>
                <section className="main-section container">
                    <header className="text-center contained detail-header">
                        <h3 className="heading-title text-uppercase">{this.state.selected.name}</h3>
                    </header>
                    <main className="d-lg-flex">
                        <section className="col-12 col-md-4 pl-0 pr-0 owner">
                            <img className={"owner-image " + this.state.selected.className} src={this.state.selected.img} alt={this.state.selected.name}/>
                        </section>
                        <section className="col-12 col-md-8 pr-lg-0 ml-1 mt-3">
                            <section className="col-12 pr-lg-0">
                                <main className="contained">
                                    <p>{this.state.selected.blurb}</p>
                                    {this.state.selected.details &&
                                    <p>{this.state.selected.details}</p>
                                    }
                                    <ul>
                                        {this.state.selected.experience &&
                                        this.state.selected.experience.map((item, index) => {
                                            return (
                                                <li key={index}><strong>{item.year}</strong> {item.detail}</li>
                                            )
                                        })
                                        }
                                    </ul>
                                </main>
                            </section>
                            {
                                this.state.selected.socialMedia &&
                                <section className="col-12 pr-lg-0">
                                    <main>
                                        <ul className="social-media">
                                            <li><i className="fa fa-facebook"/></li>
                                            <li><i className="fa fa-facebook"/></li>
                                            <li><i className="fa fa-facebook"/></li>
                                        </ul>
                                    </main>
                                </section>
                            }
                        </section>
                    </main>
                </section>
                <Footer/>
                <Copyright/>
            </main>
        );
    }

}

export default OwnerDetail;
