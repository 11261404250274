import React, {Component} from 'react';

import "../stylesheets/About.scss";

class About extends Component {

    state = {
        interests: [
            "Injury Rehabilitation",
            "Pre + Post Surgery Rehabilitation",
            "Chronic Disease Management",
            "Lifestyle Management",
            "Sports Conditioning",
            "Neurological Disorder Management"
        ]
    };

    render() {
        return (
            <section className="container-fluid">
                <section className="container contained" id="about">
                    <header className="text-center">
                        <h3 className="heading-title text-uppercase">About</h3>
                    </header>
                    <main className="container box interests">
                        <div className="row">
                            <div className="col-12 p-4">
                                <section>
                                    <header className="mb-4 text-center">
                                        <h5 className="heading-title text-uppercase">We have special interest in</h5>
                                    </header>
                                    <main className="mb-4 text-center">
                                        <ul>
                                            {
                                                this.state.interests.map((item, index) => {
                                                    return (<li key={index}>{item}</li>);
                                                })
                                            }
                                        </ul>
                                    </main>
                                </section>
                            </div>
                        </div>
                    </main>
                </section>
            </section>
        );
    }

}

export default About;
