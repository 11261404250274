import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import './App.scss';

import OwnerDetail from "./components/detail/OwnerDetail";
import Main from "./components/Main";
import BioDetail from "./components/detail/BioDetail";
import ServiceDetail from "./components/detail/ServiceDetail";

class App extends Component {
    render() {
        return (
            <Router onUpdate={() => window.scrollTo(0, 0)}>
                <Route path="/" exact component={Main} />
                <Route path="/bios/:id" component={OwnerDetail} />
                <Route path="/biokinetics/:id" component={BioDetail} />
                <Route path="/services/:id" component={ServiceDetail} />
            </Router>
        );
    }
}

export default App;
