import React, {Component} from "react";

import "../../stylesheets/ServiceDetail.scss";

import AltHeader from "../AltHeader";
import Copyright from "../Copyright";
import Footer from "../Footer";

class ServiceDetail extends Component {

    state = {
        services: [{
            id: 1,
            img: "/images/biodex.jpg",
            name: "Isokinetics - Biodex",
            blurb: "Biodex Isokinetic machines are highly specialised and are used in most of our orthopaedic assessments " +
                "and as part of rehabilitation. The system allows us to quantify weaknesses, providing an objective measure " +
                "to track improvement.",
            details: "There are a number of benefits associated with isokinetic exercise. " +
                "Because the amount of resistance can be controlled, it is possible to set the level so that the body " +
                "is challenged but not in danger of straining or pulling the muscles. This can help maximize the benefit " +
                "from the workout, in that the muscles are loaded adequately without necessarily placing a dangerous " +
                "load on the joint itself causing any discomfort. Instead of feeling fatigued after a workout, it is " +
                "not unusual for the individual to feel more energetic than before he or she started the isokinetic exercise.",
            facts: [{
                prefact: "The biodex allows us to:",
                details: [{
                    detail: "Identify, treat and document physical impairments that cause functional limitations."
                }, {
                    detail: "Perform Neuromuscular testing and rehabilitation."
                }, {
                    detail: "Apply dynamic and static muscle loading."
                }, {
                    detail: "Perform impact-free acceleration and deceleration."
                }, {
                    detail: "Eliminates joint trauma."
                }, {
                    detail: "Exercise and test at more functional speeds."
                }, {
                    detail: "Exercise the joint pre and post operatively safely and without joint load."
                }, {
                    detail: "Rrain patients safely for any phase of rehabilitation."
                }]
            }]
        }, {
            id: 2,
            img: "/images/wattbike.jpg",
            name: "Discovery Vitality Fitness Assessment",
            blurb: "The Vitality Fitness Assessment is made up of two parts – the first part measures your " +
                "cardio-vascular fitness and the second measures your strength and flexibility.",
            facts: [{
                prefact: "It has been designed to give you an accurate and personalised understanding of your fitness. " +
                    "After the Vitality Fitness Assessment, your biokineticist will show you what you need to focus on in " +
                    "order to get fitter and stronger."
            }],
            details: "Everything you need for your assessment: Comfortable clothes, running or walking shoes, a gym towel. " +
                "The assessment will take 45min to complete. You can earn up to 7500 points depending on your fitness level."
        }, {
            id: 3,
            img: "/images/multiply.jpg",
            name: "Momentum Multiply Assessment",
            blurb: "A Multiply fitness assessment is an alternative to getting Active Dayz™ for members who live an " +
                "active lifestyle but are unable to track it. You will earn points towards your multiply status for " +
                "completing a multiply fitness assessment at a Biokineticist. Your assessment is valid for 6 months.",
            facts: [{
                prefact: "It involves a fitness test to determine cardiovascular fitness, upper and lower body flexibility, " +
                    "peak flow and body fat percentage. The assessment takes 20 mins with your biokineticist.",
            }],
            details: "Wear comfortable clothing, like track pants, tights or shorts and running or walking shoes. " +
                "Take your gym towel. You will receive comprehensive feedback and an overall fitness level (ranging from 1 – 5) " +
                "which can be printed and or emailed to you."
        }, {
            id: 4,
            img: "/images/pab2.jpg",
            name: "PAB (Pressure Air Biofeedback)",
            blurb: "The PAB® uses air, to measure muscle performance in a simple and scientific way. " +
                "PAB® as a portable device is used for clinical and high performance testing including biofeedback while " +
                "exercising. PAB®measures strength as well as various other physical parameters in a valid and reliable way."
        }, {
            id: 5,
            img: "/images/balance-system.png",
            name: "HUMAC Balance",
            facts: [{
                prefact: "The Humac Balance is a computerized Balance Measurement and Training Systems and is ideal for " +
                    "the following patients & applications:",
                details: [{
                    detail: "Orthopedic Post-Surgical & Post Trauma."
                }, {
                    detail: "Neurological Disorders."
                }, {
                    detail: "Concussion."
                }, {
                    detail: "Older Adult Fall Prevention."
                }, {
                    detail: "Vestibular Disorders."
                }]
            }, {
                prefact: "The HUMAC Balance System includes the following Measurement & Training Routines:",
                details: [{
                    detail: "Random Motion."
                }, {
                    detail: "M-CTSIB."
                }, {
                    detail: "Weight Shift."
                }, {
                    detail: "Roadway."
                }, {
                    detail: "Limits of Stability."
                }, {
                    detail: "Center of Pressure."
                }, {
                    detail: "Curves."
                }, {
                    detail: "Weight Bearing."
                }]
            }]
        }, {
            id: 6,
            img: "/images/concussion-test.jpg",
            name: "Concussion Baseline Testing",
            blurb: "It is better for the athlete to be tested pre-season so that the test can be used as a Baseline test. " +
                "As soon as an athlete experiences a blow/nock to the head they should try and retest as soon as possible " +
                "so that the results can be compared to the Baseline test. The athlete can then use the information " +
                "and show it to their General Practitioner for Final Clearance to return to Sport.",
            facts: [{
                prefact: "Concussion Baseline Testing focused on:",
                details: [{
                    detail: "Motor-neuron control (Balance)"
                }, {
                    detail: "Cervical (Neck) Range of Motion"
                }, {
                    detail: "Cervical (Neck) strength"
                }]
            }, {
                prefact: "The test contains the following procedures:"
            }, {
                prefact: "Humac Balance",
                bold: true,
                details: [{
                    detail: "The athlete will be asked to stand on an Electronic Computerized Balancebord " +
                        "that will measure how the athlete carries and distributes their body weight " +
                        "(Leg Dominance, Heel/ Midfoot/ Toe Dominance)."
                }]
            }, {
                prefact: "Cervical (Neck) Range of Motion",
                bold: true,
                details: [{
                    detail: "Lateral Flexion (Left/Right)"
                }, {
                    detail: "Rotation (Left/Right)"
                }, {
                    detail: "Flexion"
                }, {
                    detail: "Extension"
                }]
            }, {
                prefact: "Cervical (Neck) strength",
                bold: true,
                details: [{
                    detail: "Neck Flexion"
                }, {
                    detail: "Neck Extension"
                }]
            }]
        }],
        selected: {}
    };

    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({
            selected: this.state.services.find(service => service.id === Number(params.id))
        });
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main>
                <AltHeader/>
                <section className="main-section container">
                    <header className="text-center contained detail-header">
                        <h3 className="heading-title text-uppercase">{this.state.selected.name}</h3>
                    </header>
                    <main className="d-lg-flex">
                        <section className="col-12 col-md-4 pl-0 pr-0 owner">
                            <img className="owner-image" src={this.state.selected.img} alt={this.state.selected.name}/>
                        </section>
                        <section className="col-12 col-md-8 pr-lg-0 ml-1 mt-3">
                            <section className="col-12 pr-lg-0">
                                <main className="contained">
                                    <p>{this.state.selected.blurb}</p>
                                    {this.state.selected.facts &&
                                    this.state.selected.facts.map((fact, index) => {
                                        return (
                                            <div key={index}>
                                                {fact.prefact && fact.bold &&
                                                <p><strong>{fact.prefact}</strong></p>
                                                }
                                                {fact.prefact && !fact.bold &&
                                                <p>{fact.prefact}</p>
                                                }
                                                <ul>
                                                    {fact.details &&
                                                        fact.details.map((item, index) => {
                                                            return (
                                                                <li key={index}>{item.detail}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                    }
                                    {this.state.selected.details &&
                                    <p>{this.state.selected.details}</p>
                                    }
                                </main>
                            </section>
                        </section>
                    </main>
                </section>
                <Footer/>
                <Copyright/>
            </main>
        );
    }

}

export default ServiceDetail;
