import React, {Component} from 'react';
import { Link } from "react-router-dom";

import "../stylesheets/Header.scss";

class AltHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: [{
                title: "Home",
                href: "home"
            }]
        };
    }

    render() {
        return (
            <header className="header">
                <ul className="nav justify-content-center">
                    {
                        this.state.navigation.map((item, index) => {
                            return (
                                <li className="nav-item text-uppercase" key={index}>
                                    <Link to="/">
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        })
                    }
                </ul>
            </header>
        );
    }

}

export default AltHeader;
