import React, { Component } from 'react';

import "../stylesheets/Biokinetics.scss";
import {Link} from "react-router-dom";

class Biokinetics extends Component {

    state = {
        items: [{
            id: 1,
            img: "/images/emg.jpg",
            name: "EMG",
            title: "Electromyography",
            details: "Electromyography is a diagnostic test that records the electrical activity of white muscle fibers. " +
                "This test can give us an indication of how fast the patient can elicit a muscular contraction, " +
                "the strength of the contraction, the endurance of the contraction as well as how fast the patient " +
                "can deactivate the contraction."
        }, {
            id: 2,
            img: "/images/ems.jpeg",
            name: "EMS",
            title: "Electrical Muscle Stimulation",
            details: "Electrical muscle stimulation is used to elicit muscle contraction using electrical impulses to " +
                "assist in strengthening and isolating atrophied muscles during rehab."
        }, {
            id: 3,
            img: "/images/service.png",
            name: "FMS",
            title: "Functional Movement Screening",
            details: "Functional Movement Screening uses basic movement patterns to identify muscular imbalances. " +
                "It involves 7 movement tests that identifies dysfunction or asymmetry in the patient’s functional " +
                "movements which would need to be corrected through exercises and stretches. Each movement is scored " +
                "and therefore can be reassessed and compared which can indicate whether the patient can return to " +
                "sport or needs to continue with rehab."
        }]
    };

    render() {
        return (
            <section className="container-fluid">
                <section className="container contained" id="bio">
                    <header className="text-center">
                        <h3 className="heading-title text-uppercase">Biokinetics</h3>
                    </header>
                    <main className="container">
                        <div className="row">
                            {
                                this.state.items.map((item, index) => {
                                    return (
                                        <div className="col-12 col-md-6 col-lg-4 p-2" key={index}>
                                            <div className="bio-item">
                                                <img className="item-image" src={item.img} alt={item.title}/>
                                                <div className="item-detail">
                                                    <h3 className="item-heading">{item.name}</h3>
                                                    <h5 className="text-uppercase">({item.title})</h5>
                                                    {item.details &&
                                                    <p>{item.details}</p>
                                                    }
                                                    <Link to={"/biokinetics/" + item.id}
                                                          className="text-uppercase d-md-none">More >></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </main>
                </section>
            </section>
        );
    }

}

export default Biokinetics;
