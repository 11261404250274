import React, {Component} from 'react';

import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Biokinetics from "./Biokinetics";
import Footer from "./Footer";
import Copyright from "./Copyright";
import Owners from "./Owners";

class Main extends Component {

    render() {
        return (
            <main>
                <Home/>
                <About/>
                <Owners/>
                <Services/>
                <Biokinetics/>
                <Footer/>
                <Copyright/>
            </main>
        );
    }

}

export default Main;
