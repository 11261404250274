import React, { Component } from 'react';
import {Link} from "react-scroll/modules";

import Header from "./Header";

import "../stylesheets/Home.scss";

class Home extends Component {

    render() {
        return (
            <section id="home">
                <Header/>
                <video autoPlay muted loop id="background">
                    <source src="/images/background.mp4" type="video/mp4" />
                </video>
                <main className="jumbotron jumbotron-full-height">
                    <div className="cover">
                        <div className="container">
                            <div className="logo">
                                <img src="/images/logo.png" alt="PB Logo"/>
                            </div>

                            <h1>Pienaar &amp; Willis</h1>
                            <h3>Biokineticist</h3>

                            <h5>Life through movement</h5>
                            <Link
                                className="btn btn-primary btn-round"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={1000}
                            >Book an appointment
                            </Link>
                        </div>
                    </div>
                </main>
            </section>
        );
    }

}

export default Home;
