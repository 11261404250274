import React, {Component} from "react";

import "../../stylesheets/ServiceDetail.scss";

import AltHeader from "../AltHeader";
import Copyright from "../Copyright";
import Footer from "../Footer";

class ServiceDetail extends Component {
    state = {
        items: [{
            id: 1,
            img: "/images/emg.jpg",
            name: "EMG",
            title: "Electromyography",
            details: "Electromyography is a diagnostic test that records the electrical activity of white muscle fibers. " +
                "This test can give us an indication of how fast the patient can elicit a muscular contraction, " +
                "the strength of the contraction, the endurance of the contraction as well as how fast the patient " +
                "can deactivate the contraction."
        }, {
            id: 2,
            img: "/images/ems.jpeg",
            name: "EMS",
            title: "Electrical Muscle Stimulation",
            details: "Electrical muscle stimulation is used to elicit muscle contraction using electrical impulses to " +
                "assist in strengthening and isolating atrophied muscles during rehab."
        }, {
            id: 3,
            img: "/images/service.png",
            name: "FMS",
            title: "Functional Movement Screening",
            details: "Functional Movement Screening uses basic movement patterns to identify muscular imbalances. " +
                "It involves 7 movement tests that identifies dysfunction or asymmetry in the patient’s functional " +
                "movements which would need to be corrected through exercises and stretches. Each movement is scored " +
                "and therefore can be reassessed and compared which can indicate whether the patient can return to " +
                "sport or needs to continue with rehab."
        }],
        selected: {}
    };

    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({
            selected: this.state.items.find(item => item.id === Number(params.id))
        });
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main>
                <AltHeader/>
                <section className="main-section container">
                    <header className="text-center contained detail-header">
                        <h3 className="heading-title text-uppercase d-none d-md-block">{this.state.selected.title}</h3>
                        <h3 className="heading-title text-uppercase d-block d-md-none">{this.state.selected.name}</h3>
                    </header>
                    <main className="d-lg-flex">
                        <section className="col-12 col-md-4 pl-0 pr-0 owner">
                            <img className="owner-image" src={this.state.selected.img} alt={this.state.selected.name}/>
                        </section>
                        <section className="col-12 col-md-8 pr-lg-0 ml-1 mt-3">
                            <section className="col-12 pr-lg-0">
                                <main className="contained">
                                    {this.state.selected.details &&
                                    <p>{this.state.selected.details}</p>
                                    }
                                </main>
                            </section>
                        </section>
                    </main>
                </section>
                <Footer/>
                <Copyright/>
            </main>
        );
    }

}

export default ServiceDetail;
