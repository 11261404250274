import React, { Component } from 'react';

import "../stylesheets/Footer.scss";

class Footer extends Component {

    constructor(props) {
        super(props);

        this.changeValue = this.changeValue.bind(this);
        this.state = {
            location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14334.085761036331!2d28.024862!3d-26.08189" +
                "2!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9c44de3215455dff!2sPienaar+C+and+Bresler+C+A!5e0!3m2!1sen!2sza" +
                "!4v1556279552019!5m2!1sen!2sza",
            subject: ""
        };
    }

    changeValue(e) {
        e.preventDefault();

        console.log(e.target);
        this.setState({
            subject: e.target.value
        })
    }

    render() {
        return (
            <section className="container-fluid contained bg-secondary" id="contact">
                <div className="container d-lg-flex">
                    <main className="col-12 col-lg-6">
                        <header className="text-center">
                            <h3 className="heading-title text-uppercase">Contact</h3>
                        </header>
                        <main className="pt-5 pb-5 p-lg-5">
                            <form className="row contact-us" encType="text/plain" method="post"
                                  action={"mailto:info@pienaarwillisbiokineticist.co.za?subject=" + this.state.subject}>
                                <div className="col-12 col-lg-6 mb-3">
                                    <input name="name" type="text" placeholder="Name" className="form-control" />
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                    <input name="email" type="email" placeholder="Email" className="form-control" />
                                </div>
                                <div className="col-12 mb-3">
                                    <input name="subject" type="text" placeholder="Subject" className="form-control"
                                           onChange={this.changeValue} value={this.state.subject}/>
                                </div>
                                <div className="col-12 mb-3">
                                    <textarea name="message" placeholder="Message" className="form-control message" />
                                </div>
                                <div className="col-12 text-center">
                                    <button className="btn btn-round" type="submit">Submit</button>
                                </div>
                            </form>
                        </main>
                    </main>
                    <main className="col-12 col-lg-6">
                        <header className="text-center">
                            <h3 className="heading-title text-uppercase">The Clinic</h3>
                        </header>
                        <main className="pt-5 pb-5">
                            <iframe title="Head Office" className="map"
                                    src={this.state.location} frameBorder="0" allowFullScreen />
                        </main>
                        <footer>
                            <section className="d-lg-flex">
                                <div className="col-12 col-lg-6">
                                    <ul className="contacts">
                                        <li>Sandton Medi Clinic</li>
                                        <li>North Block, Room G02</li>
                                        <li><a href="mailto:info@pienaarwillisbiokineticist.co.za">info@pienaarwillisbiokineticist.co.za</a></li>
                                        <li><a href="tel:+27117060070">011 706 0070</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <ul className="contacts">
                                        <li>Opening Hours:</li>
                                        <li>Mon - Fri: 6am - 6pm</li>
                                        <li>Saturday: Closed</li>
                                        <li>Sunday: Closed</li>
                                    </ul>
                                </div>
                            </section>
                            <section className="text-center w-100">
                                <ul className="social-media">
                                    <li><a href="https://www.facebook.com/PienaarAndBreslerBiokineticist/"><i className="fa fa-facebook"/></a></li>
                                    <li><a href="https://www.instagram.com/pw_biokineticist/"><i className="fa fa-instagram"/></a></li>
                                </ul>
                            </section>
                        </footer>
                    </main>
                </div>
            </section>
        );
    }

}

export default Footer;
