import React, {Component} from 'react';
import {Link} from "react-router-dom";

import "../stylesheets/Owners.scss";

class Owners extends Component {

    state = {
        owners: [{
            id: 1,
            className: "chineldi",
            img: "/images/owners/chineldi.jpg",
            name: "Chineldi Pienaar",
            blurb: "Chineldi has a passion for people and loves her practice. She has a special interest in orthopaedic " +
                "conditions, as well as performance enhancement and injury prevention in young sportsmen and women."
        }, {
            id: 2,
            img: "/images/owners/leandri.jpg",
            className: "leandri",
            name: "Leandri Willis",
            blurb: "Leandri is passionate about sport, she has a special interest in athletics and loves working with " +
                "different types of sport related injuries.",
        }, {
            id: 3,
            img: "/images/owners/leane.jpg",
            className: "leane",
            name: "Leané Du Plessis",
            blurb: "Leané lives out her love for the human body through Biokinetics, where she has a big interest in " +
                "Orthopaedic conditions as well as Neurological conditions."
        }, {
            id: 4,
            img: "/images/owners/miandi.jpg",
            className: "miandi",
            name: "Miandi Meyer",
            blurb: "Miandi has always been fascinated by health and the human body. She is passionate about people and " +
                "being a Biokineticist enables her to make a positive contribution to people’s lives by improving " +
                "their wellbeing."
        }, {
            id: 5,
            img: "/images/owners/kalinka.jpg",
            className: "kalinka",
            name: "Kalinka Terre Blanché",
            blurb: "Kalinka just finished her Honors Degree in Biokinetics at the University of the North West during which " +
                "she found a special interest in specifically rehabilitation of sport injuries, children and posture related injuries. " +
                "Kalinka enjoys Crossfit, running and being outdoors. She firmly believes in the saying practice what you " +
                "preach and that with hard work there is little one cannot achieve."
        }, {
            id: 6,
            img: "/images/owners/kathryn.jpg",
            className: "kathryn",
            name: "Kathryn Brandt",
            blurb: "Kathryn recently graduated with BHSC Honours in Biokinetics at Wits University where she found interest " +
                "in chronic disease management, children and sports related injury. Kathryn enjoys Pilates and various " +
                "forms of cardio such as swimming and running. Kathryn believes strongly in helping others to reach " +
                "their goals and improving their abilities."
        }]
    };

    render() {
        return (
            <section className="container-fluid contained d-flex justify-content-between">
                <div className="row owner-component justify-content-center justify-content-around w-md-100">
                {
                    this.state.owners.map((item, index) => {
                        return (
                            <div className="owner m-2" key={index}>
                                <img className={"owner-image " + item.className} src={item.img} alt={item.name}/>
                                <div className="owner-detail flex-column justify-content-center">
                                    <h5 className="heading-title-sec text-uppercase name">{item.name}</h5>
                                    {/*<p>{item.blurb}</p>*/}
                                    {/*{item.socialMedia &&*/}
                                    {/*<ul className="social-media">*/}
                                    {/*    <li><i className="fa fa-linkedin"/></li>*/}
                                    {/*    <li><i className="fa fa-facebook"/></li>*/}
                                    {/*    <li><i className="fa fa-twitter"/></li>*/}
                                    {/*</ul>*/}
                                    {/*}*/}
                                    <Link to={"/bios/" + item.id} className="text-uppercase">More >></Link>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </section>
        );
    }

}

export default Owners;
