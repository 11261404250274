import React, {Component} from 'react';

import "../stylesheets/Services.scss";
import {Link} from "react-router-dom";

class Services extends Component {

    state = {
        services: [{
            id: 1,
            img: "/images/biodex.jpg",
            name: "Isokinetics - Biodex",
            details: "Biodex Isokinetic machines are highly specialised and are used in most of our orthopaedic assessments " +
                "and as part of rehabilitation. The system allows us to quantify weaknesses, providing an objective measure " +
                "to track improvement."
        }, {
            id: 2,
            img: "/images/wattbike.jpg",
            name: "Discovery Vitality Fitness Assessment",
            details: "It has been designed to give you an accurate and personalised understanding of your fitness. " +
                "After the Vitality Fitness Assessment, your biokineticist will show you what you need to focus on in " +
                "order to get fitter and stronger."
        }, {
            id: 3,
            img: "/images/multiply.jpg",
            name: "Momentum Multiply Assessment",
            details: "A Multiply fitness assessment is an alternative to getting Active Dayz™ for members who live an " +
                "active lifestyle but are unable to track it. You will earn points towards your multiply status for " +
                "completing a multiply fitness assessment at a Biokineticist. Your assessment is valid for 6 months."
        }, {
            id: 4,
            img: "/images/pab2.jpg",
            name: "PAB (Pressure Air Biofeedback)",
            details: "The PAB® uses air, to measure muscle performance in a simple and scientific way. " +
                "PAB® as a portable device is used for clinical and high performance testing including biofeedback while " +
                "exercising. PAB®measures strength as well as various other physical parameters in a valid and reliable way."
        }, {
            id: 5,
            img: "/images/balance-system.png",
            name: "HUMAC Balance",
            details: "The Humac Balance is a computerized Balance Measurement and Training System."
        }, {
            id: 6,
            img: "/images/concussion-test.jpg",
            name: "Concussion Baseline Testing",
            details: "It is better for the athlete to be tested pre-season so that the test can be used as a Baseline test."
        }]
    };

    render() {
        return (
            <section className="container-fluid contained" id="services">
                <header className="text-center">
                    <h3 className="heading-title text-uppercase">Services</h3>
                </header>
                <main className="container-fluid">
                    {
                        this.state.services.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="service">
                                        <div className="service-content">
                                            <img className={"service-image float-lg-left" + (index % 2 === 0 ? '' : ' d-lg-none') }
                                                 src={item.img} alt={item.name}/>
                                        </div>
                                        <div className="service-content">
                                            <h2 className="text-uppercase">{item.name}</h2>
                                            <div>
                                                <p>{item.details}</p>
                                                <Link to={"/services/" + item.id} className="btn btn-primary btn-round text-uppercase">View More</Link>
                                            </div>
                                        </div>
                                        <div className="service-content d-none d-lg-flex">
                                            <img className={"service-image float-left" + (index % 2 === 0 ? ' d-lg-none' : '') }
                                                 src={item.img} alt={item.name}/>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )
                        })
                    }
                </main>
            </section>
        );
    }

}

export default Services;
