import React, { Component } from 'react';

import "../stylesheets/Copyright.scss";

class Copyright extends Component {

    render() {
        return (
            <section className="container-fluid text-center p-2 footer">
                <span>&copy; 2021 Pienaar &amp; Willis Biokineticist. All Rights Reserved.
                    Designed by <a className="designer" href="https://neslotech.co.za">Neslo Technologies (PTY) Ltd.</a></span>
            </section>
        );
    }

}

export default Copyright;
